import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import Entry from "../components/entry";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

type DataProps = {
  allWpPost: {
    posts: {
      id: string;
      slug: string;
      uri: string;
      featuredImage: { node: ImageDataLike };
    }[];
  };
};

const PhotosPage = ({ data }: PageProps<DataProps>) => {
  console.log({ data });
  return (
    <Layout
      isHome={false}
      mainClassName="w-full p-6 md:px-24 flex flex-wrap gap-4 md:gap-2 lg:gap-4"
    >
      <Seo title={"Foton"} />

      {data.allWpPost.posts.map((post) => {
        const image = getImage(post.featuredImage.node);
        console.log("image", image);

        return (
          image && (
            <Link to={post.uri} className="flex grow">
              <GatsbyImage
                image={image}
                alt=""
                imgClassName=""
                className="md:h-[40vh] flex grow"
                style={{ aspectRatio: `${image.width}/${image.height}` }}
              />
            </Link>
          )
        );
      })}
    </Layout>
  );
};

export const pageQuery = graphql`
  query PhotosPage {
    allWpPost(
      filter: { featuredImageId: { ne: null } }
      sort: { fields: [date], order: DESC }
    ) {
      posts: nodes {
        id
        slug
        uri
        featuredImage {
          node {
            gatsbyImage(width: 800, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default PhotosPage;
